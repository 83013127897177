<template>
  <b-sidebar id="sidebar-detail" no-close-on-esc no-close-on-backdrop v-model="isDetailsActive" :title="titleSidebar"
    header-class="text-primary py-2 px-2 text-bold" width="85%" bg-variant="white" shadow backdrop right @hidden="close">
    <div class="mb-2 px-2">
      <b-card no-body class="border-top-primary border-1 border-table-radius px-0">


        <div class="d-flex  justify-content-end">
          <div class="d-flex justify-content-end cursor-pointer mt-2">
            <div class="av-budget-border av-budget-border-blue float-right d-flex">
              <div class="av-budget-text av-budget-text-blue text-center">
                Total Predefined<span class="txt-budget">Budget</span>
              </div>
              <h2 class="av-budget-content av-budget-content-blue">
                S/. {{ totalBudget | currency }}
              </h2>
            </div>
          </div>

          <div class="d-flex justify-content-end cursor-pointer ml-2 mt-2">
              <div class="av-budget-border float-right d-flex"
              :class="available >= 0 ? 'av-budget-border-success': 'av-budget-border-danger'"
              >
                <div class=" av-budget-text  text-center"
                :class="available >= 0 ? 'av-budget-text-success': 'av-budget-text-danger'"
                >
                  Available<span class="txt-budget">Budget</span>
                </div>
                <h2 class="av-budget-content "
                :class="available >= 0 ? 'av-budget-content-success' : 'av-budget-content-danger'">
                  S/. {{ available | currency }}
                </h2>
              </div>
          </div>
        </div>

        <budget-event-table :sendBudgetId="Budgetid" :sendYearBudget="YearBudget" :sendValueBugdet="totalBudget"
          @reload="refreshBudgetTable" @updateTotal="updateAvailable" />
      </b-card>
    </div>
  </b-sidebar>
</template>
    
<script>

import BudgetEventTable from "@/views/logistic/views/budget/views/components/BudgetEventTable.vue"

export default {

  props: {

    sendArrBudget: {
      type: Array,
      default: []
    }
  },
  components: {
    BudgetEventTable,
  },
  data() {
    return {
      isDetailsActive: false,
      totalBudget: 0,
      available: 0,
      Budgetid: 0,
      YearBudget: 0,
      titleSidebar: "Event Budget"
    }
  },
  created() {
    this.isDetailsActive = true;
    this.totalBudget = parseFloat(this.sendArrBudget[0].budget);
    this.Budgetid = this.sendArrBudget[0].id;
    this.YearBudget = this.sendArrBudget[0].year;
    this.titleSidebar = "Event Budget " + this.YearBudget;


  },
  methods: {
    close() {
      this.$emit('close')
    },
    updateAvailable(costTotal) {
      this.available = this.totalBudget - costTotal;
    },

    refreshBudgetTable() {
      this.$emit('reload')
    }

  },
}
</script>
  
<style scoped>
.txt-budget {
  display: block;
  margin-top: 5px;
}

.av-budget-border {
  border-radius: 7px;
  width: auto;
  padding: 3px;
}

.av-budget-border-blue {
  border: 1px solid #0073B9;
}

.av-budget-border-success {
  border: 1px solid #00A300;
}

.av-budget-border-danger {
  border: 1px solid #cc0000;
}



.av-budget-text {
  width: 150px;
  line-height: 14px;
  padding: 7px;
  font-weight: 300;
  margin: 0;
  font-size: 15px;
  font-family: sans-serif;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 7px;
}

.av-budget-text-blue {
  color: white;
  background-color: #0677be;
}

.av-budget-text-success {
  color: white;
  background-color: #1b6606;
}

.av-budget-text-danger{
  color: white;
  background-color: #ba0e0e;
}

.av-budget-content {
  font-size: 1.1rem;
  padding: 12px;
  padding-top: 17px;
  font-weight: bold;
  margin: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.av-budget-content-blue {
  color: white;
  background-color: #0e99ef;
}

.av-budget-content-success {
  color: white;
  background-color: #00A300;
}

.av-budget-content-danger {
  color: white;
  background-color: #e91010;
}


/* .av-budget-border-success{
    color: white;
    background-color: #9fed4c;
  }
  .av-budget-border-success{
    border: 1px solid #00b937;
  } */
</style>
  