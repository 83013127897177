import { amgApi } from "@/service/axios";

class BudgetService{

    async getYearlyBudget(parameters){
        try{
            const data = await amgApi.post('logistics/budget/get-yearly-budget',parameters)
            return data.data
        }catch(error){
            console.log(error)            
        }
    }

    async saveYearlyBudget(parameters){
        try{
            const data = await amgApi.post('logistics/budget/save-yearly-budget',parameters)
            return data
        }catch(error){
            console.log(error)            
        }
    }


    async deleteYearlyBudget(parameters){
        try{
            const data = await amgApi.post('logistics/budget/delete-yearly-budget',parameters)
            return data
        }catch(error){
            console.log(error)            
        }
    }

    async getTrackingYearlyBudget(parameters){
        try{
            const data = await amgApi.post('logistics/budget/get-tracking-yearly-budget',parameters)
            return data.data
        }catch(error){
            console.log(error)            
        }
    }

    async getEvent(){
        try{
            const data = await amgApi.post('logistics/budget/get-event')
            return data
        }catch(error){
            console.log(error)            
        }
    }


    async saveEvent(parameters){
        try{
            const data = await amgApi.post('logistics/budget/save-event',parameters)
            return data
        }catch(error){
            console.log(error)            
        }
    }

    async getEventType(){
        try{
            const data = await amgApi.post('logistics/budget/get-event-type')
            return data
        }catch(error){
            console.log(error)            
        }
    }

    async saveEventType(parameters){
        try{
            const data = await amgApi.post('logistics/budget/save-event-type',parameters)
            return data
        }catch(error){
            console.log(error)            
        }
    }

    async getCountry(){
        try{
            const data = await amgApi.post('logistics/budget/get-country')
            return data
        }catch(error){
            console.log(error)            
        }
    }

    async saveBudgetEventDetails(parameters){
        try{
            const data = await amgApi.post('logistics/budget/save-budget_event_details',parameters)
            return data
        }catch(error){
            console.log(error)            
        }
    }


    async getBudgetEvent(parameters){
        try{
            const data = await amgApi.post('logistics/budget/get-budget-event',parameters)
            return data
        }catch(error){
            console.log(error)            
        }
    }

    async getBudgetEventDetails(parameters){
        try{
            const data = await amgApi.post('logistics/budget/get-budget-event-details',parameters)
            return data
        }catch(error){
            console.log(error)            
        }
    }



    async deleteBudgetEventDetails(parameters){
        try{
            const data = await amgApi.post('logistics/budget/delete-budget-event-detail',parameters)
            return data
        }catch(error){
            console.log(error)            
        }
    }


    async getTrackingBudgetEventDetails(parameters){
        try{
            const data = await amgApi.post('logistics/budget/get-tracking-budget-event-details',parameters)
            return data.data
        }catch(error){
            console.log(error)            
        }
    }
   


    

   

}

export default new BudgetService