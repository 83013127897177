<template>
  <b-modal
    v-model="onControl"
    :title="formTitle"
    title-tag="h3"
    modal-class="modal-primary"
    @hidden="close"
    size="md"
    no-close-on-backdrop
  >
    <validation-observer ref="budgetEventForm">
      <div class="d-block">
        <b-container>
          <b-row>
            <b-col sm="6">
              <validation-provider
                name="rule_name"
                rules="required"
                v-slot="{ errors }"
              >
                <feather-icon
                  class="pointer text-primary"
                  style="float: right"
                  icon="PlusCircleIcon"
                  size="16"
                  @click="openModalAddEvent()"
                />
                <b-form-group
                  label="Name:"
                  label-for="optEvent"
                  :state="errors[0] ? false : null"
                >
                  <v-select
                    v-model="selectEvent"
                    :reduce="(user) => user.id"
                    :options="optionsEvent"
                    :disabled="disabledEvent"
                    label="name"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >Please select an option</b-form-select-option
                      >
                    </template>
                  </v-select>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col sm="6">
              <validation-provider
                v-slot="{ errors }"
                name="validate_type"
                rules="required"
              >
                <feather-icon
                  class="pointer text-primary"
                  style="float: right"
                  icon="PlusCircleIcon"
                  size="16"
                  @click="openModalAddEventType()"
                />
                <b-form-group
                  label="Type:"
                  label-for="optType"
                  :state="errors[0] ? false : null"
                >
                  <v-select
                    id="optType"
                    v-model="selectEventType"
                    :reduce="(user) => user.id"
                    :options="optionsEventType"
                    label="name"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >Please select an option</b-form-select-option
                      >
                    </template>
                  </v-select>
                </b-form-group>
              </validation-provider>
            </b-col>

          
          </b-row>
          <b-row>
            <b-col sm="6">
              <validation-provider
                v-slot="{ errors }"
                name="validation-country"
                rules="required"
              >
                <b-form-group
                  label="Country:"
                  label-for="optCountry"
                  :state="errors[0] ? false : null"
                >
                  <v-select
                    v-model="selectCountry"
                    :reduce="(user) => user.id"
                    :options="optionsCountry"
                    label="name"
                  >
                  </v-select>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col sm="6">
              <validation-provider rules="required" v-slot="{ errors }">
                <b-form-group
                  label="Holiday:"
                  label-for="input-3"
                  :state="errors[0] ? false : null"
                >
                  <v-select
                    v-model="isholiday"
                    :reduce="(user) => user.id"
                    :options="optionsHoliday"
                    label="name"
                
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >Please select an option</b-form-select-option
                      >
                    </template>
                  </v-select>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="6">
              <validation-provider rules="required" v-slot="{ errors }">
                <b-form-group
                  label="Date:"
                  label-for="txtDate"
                  class="m-0 p-0"
                  :state="errors[0] ? false : null"
                >
                  <b-form-datepicker
                    v-model="dateEvent"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    :state="errors[0] ? false : null"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col sm="6">
              <validation-provider
                v-slot="{ errors }"
                name="rule_cost"
                rules="validate-amount"
              >
                <b-form-group label="Cost:" label-for="txtCost">
                  <money 
                  v-model="cost" 
                  v-bind="vMoney" 
                  class="form-control" 
                  :class="errors[0] ? 'border-danger' : ''"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <validation-provider 
              v-slot="{ errors }" 
              name="rule_detail" 
              rules="valid-detail"
              >
                <b-form-group
                  id="input-group-3"
                  label="Detail:"
                  label-for="txtDetail"
                >
                  <b-form-textarea
                    id="txtDetail"
                    size="sm"
                    v-model="detailEvent"
                    :state="errors[0] ? false : null"
                    placeholder="Detail"
                    style="font-size:13.5px"
                  ></b-form-textarea>
                  <span
                    v-if="errors[0]"
                    class="text-danger"
                    >
                    {{ errors[0] }}</span>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

        </b-container>
      </div>
    </validation-observer>

    <b-modal
      modal
      v-model="ModalAddEvent"
      title="REGISTER EVENT"
      @ok.prevent="registerEvent"
      ok-title="SAVE"
    >
      <div>
        <ValidationObserver ref="formEvent">
          <b-row>
            <b-col sm="12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-group
                  id="input-group-2"
                  label="EVENT:"
                  label-for="txtEvent"
                >
                  <b-form-input
                    v-model="newEvent"
                    id="txtEvent"
                    placeholder="..."
                    :state="errors[0] ? false : null"
                  ></b-form-input>
                  <span v-if="errors[0]" class="text-danger">
                    Event {{ errors[0] }}</span
                  >
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        </ValidationObserver>
      </div>
    </b-modal>

    <b-modal
      modal
      v-model="ModalAddEventType"
      title="REGISTER EVENT TYPE"
      @ok.prevent="registerEventType"
      ok-title="SAVE"
    >
      <div>
        <ValidationObserver ref="formEventType">
          <b-row>
            <b-col sm="12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-group label="EVENT TYPE:" label-for="txtEventType">
                  <b-form-input
                    v-model="newEventType"
                    id="txtEventType"
                    placeholder="..."
                    :state="errors[0] ? false : null"
                  ></b-form-input>
                  <span v-if="errors[0]" class="text-danger">
                    Event Type {{ errors[0] }}</span
                  >
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        </ValidationObserver>
      </div>
    </b-modal>

    <template #modal-footer="{ cancel }">
      <button-cancel @click="cancel()"></button-cancel>
      <button-save @click="saveBudget"></button-save>
    </template>
  </b-modal>
</template>
  
<script>
import buttonSave from "@/views/commons/utilities/ButtonSave";
import buttonCancel from "@/views/commons/utilities/ButtonCancel";
import BudgetService from "@/views/logistic/views/budget/services/budget.service.js";
import { mapGetters } from "vuex";

export default {
  props: {
    sendIdBudget: {
      type: Number,
      default: null,
    },
    sendIdBugetDetails: {
      type: Number,
      default: null,
    },
    sendMod: {
      type: Boolean,
      default: false,
    },
    sendDataEvent: {
      type: Array,
      default: [],
    },
    sendCostTotal: {
      type: Number,
      default: null,
    },
    sendBudgetTotal: {
      type: Number,
      default: null,
    },
  },

  components: {
    buttonSave,
    buttonCancel,
  },

  data() {
    return {
      vMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "S/. ",
        precision: 2,
        masked: false,
        maxlength: 13,
      },
      optionsEvent: [],
      optionsCountry: [],
      optionsEventType: [],
      optionsHoliday: [
        { id: 0, name: "No" },
        { id: 1, name: "Yes" },
      ],

      arrayEvent: [],
      arrayEventType: [],
      idBudgetDetail: null,
      selectEvent: "",
      detailEvent:"",
      selectEventType: "",
      selectCountry: "",
      dateEvent: "",
      isholiday: "",
      cost: "",
      oldCosto: 0,
      formTitle: "Create Event Budget",
      newEvent: "",
      newEventType: "",
      disabledEvent: false,
      ModalAddEvent: false,
      ModalAddEventType: false,
      onControl: false,
    };
  },
  async created() {
    this.onControl = true;
    this.addPreloader();
    await Promise.all([
      this.getSelectEvent(),
      this.getSelectEventType(),
      this.getSelectCountry(),
    ]);

    this.removePreloader();
  },
  async mounted() {
    if (this.sendMod) {
      await this.getBudgetEventDetails();
      this.disabledEvent = true;
      this.idBudgetDetail = this.sendIdBugetDetails;
      this.formTitle = "Edit Event Budget";
    }
  
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async saveBudget() {
      try {
        const resultValidate = await this.$refs.budgetEventForm.validate();
        const costTotalEvent = this.sendCostTotal - this.oldCosto + this.cost;

        if (resultValidate) {
          const response = await this.showConfirmSwal();
          if (response.isConfirmed) {
            this.addPreloader();
            const data = await BudgetService.saveBudgetEventDetails({
              id: this.idBudgetDetail,
              yearly_id: this.sendIdBudget,
              event_id: this.selectEvent,
              event_type_id: this.selectEventType,
              country_id: this.selectCountry,
              fecha_event: this.dateEvent,
              is_holiday: this.isholiday,
              cost: this.cost,
              detail:this.detailEvent,
              created_by: this.currentUser.user_id,
            });
            if (data.status === 200) {
              this.showGenericSuccessSwal({});
              this.removePreloader();
              this.close();
              this.$emit("reloadTable");
              this.$emit('reloadBudgetTable');

              if (costTotalEvent > this.sendBudgetTotal) {
                this.showInfoSwal("Exceeded budget");
              }
            }
          }
        }
      } catch (error) {}
    },

    closeModalAddEvent() {
      this.ModalAddEvent = false;
    },
    openModalAddEvent() {
      this.ModalAddEvent = true;
    },

    closeModalAddEventType() {
      this.ModalAddEventType = false;
    },
    openModalAddEventType() {
      this.ModalAddEventType = true;
    },

    async getSelectEvent() {
      const { data } = await BudgetService.getEvent();
      this.optionsEvent = data;

      this.arrayEvent = [];
      data.forEach((item) => {
        this.arrayEvent.push(item.name.toLowerCase());
      });
    },

    async getSelectEventType() {
      const { data } = await BudgetService.getEventType();
      this.optionsEventType = data;

      this.arrayEventType = [];
      data.forEach((item) => {
        this.arrayEventType.push(item.name.toLowerCase());
      });
    },

    async getBudgetEventDetails() {
      const { data, status } = await BudgetService.getBudgetEventDetails({
        id: this.sendIdBugetDetails,
      });

      this.addPreloader();

      if (status === 200) {
        this.arrayBudgetEventDetails = data[0];
        this.oldCosto = data[0].cost;
        this.selectEvent = data[0].budget_event_id;
        this.selectEventType = data[0].budget_event_type_id;
        this.selectCountry = data[0].country_id;
        this.isholiday = data[0].is_holiday;
        this.cost = data[0].cost;
        this.dateEvent = data[0].fecha;
        this.detailEvent = data[0].description;
        this.removePreloader();
      }
    },

    async getSelectCountry() {
      const { data } = await BudgetService.getCountry();

      data.forEach((item, indice) => {
        if (item.iso === "US") {
          data[indice].name = "United States";
        }
      });
      this.optionsCountry = data;
    },

    async registerEvent() {
      try {
        const resultValidate = await this.$refs.formEvent.validate();
        const validateEvent = this.arrayEvent.includes(
          this.newEvent.toLowerCase().replace(/\s+$/, '')
        );
        if (resultValidate) {
          if (!validateEvent) {
            const response = await this.showConfirmSwal();
            if (response.isConfirmed) {
              this.addPreloader();
              const data = await BudgetService.saveEvent({
                name: this.newEvent,
                created_by: this.currentUser.user_id,
              });
              if (data.status === 200) {
                this.showToast(
                  "success",
                  "top-right",
                  "Success!",
                  "CheckIcon",
                  "Event has been created successfully"
                );
                this.newEvent = "";
                this.removePreloader();
                this.closeModalAddEvent();
                this.getSelectEvent();
              }
            }
          } else {
            this.showToast(
              "warning",
              "top-right",
              "Warning!",
              "AlertTriangleIcon",
              "The entered Event already exists"
            );
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    async registerEventType() {
      try {
        const resultValidate = await this.$refs.formEventType.validate();
        const validateEventType = this.arrayEventType.includes(
          this.newEventType.toLowerCase().replace(/\s+$/, '')
        );

        if (resultValidate) {
          if (!validateEventType) {
            const response = await this.showConfirmSwal();
            if (response.isConfirmed) {
              this.addPreloader();
              const data = await BudgetService.saveEventType({
                name: this.newEventType,
                created_by: this.currentUser.user_id,
              });
              if (data.status === 200) {
                this.showToast(
                  "success",
                  "top-right",
                  "Success!",
                  "CheckIcon",
                  "Event Type has been created successfully"
                );
                this.newEventType = "";
                this.removePreloader();
                this.closeModalAddEventType();
                this.getSelectEventType();
              }
            }
          } else {
            this.showToast(
              "warning",
              "top-right",
              "Warning!",
              "AlertTriangleIcon",
              "The entered Event Type already exists"
            );
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>
  
<style scoped></style>