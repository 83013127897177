export default [
    {
        key: "year",
        label: "YEAR",
        class: "text-center",
        sortable: false,
        visible: true,
    },
    {
        key: "title",
        label: "TITLE",
        class: "text-center",
        sortable: false,
        visible: true,
    },
    {
        key: "budget",
        label: "BUDGET",
        class: "text-center",
        sortable: false,
        visible: true,
    },
    
    {
        key: "used",
        label: "USED",
        class: "text-center",
        sortable: false,
        visible: true,
    },
    {
        key: "available",
        label: "AVAILABLE",
        class: "text-center",
        sortable: false,
        visible: true,
    },
    {
        key: "detail",
        label: "DETAIL",
        class: "text-center",
        sortable: false,
        visible: true,
    },
    {
        key: "assign",
        label: "EVENTS",
        class: "text-center",
        sortable: false,
        visible: true,
    },
    {
        key: "actions",
        label: "ACTIONS",
        class: "text-center",
        sortable: false,
        visible: true,
      },
      {
        key: "tracking",
        label: "TRACKING",
        class: "text-center",
        sortable: false,
        visible: true,
      },
  ];