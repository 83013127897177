export default [
   
    {
      type: 'select',
      margin: true,
      showLabel: true,
      label: 'Month',
      model: null,
      options: [],
      reduce: 'id',
      selectText: 'name',
      cols: 12,
    },

    {
        type: 'select',
        margin: true,
        showLabel: true,
        label: 'Type',
        model: null,
        options: [],
        reduce: 'id',
        selectText: 'name',
        cols: 12,
    },


    {
        type: 'select',
        margin: true,
        showLabel: true,
        label: 'Country',
        model: null,
        options: [],
        reduce: 'id',
        selectText: 'name',
        cols: 7,
    },
    {
        type: 'select',
        margin: true,
        showLabel: true,
        label: 'Holiday',
        model: null,
        options: [{
            id : 0 ,
            name :'No'
            },
            {
            id :1,
            name : 'Yes'
            }],
        reduce: 'id',
        selectText: 'name',
        cols: 5,
    }
  ]
  