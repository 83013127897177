<template>
    <b-modal
      v-model="onControl"
      header-bg-variant="primary"
      title-class="h3 text-white font-weight-bolder"
      size="xmd"
      title="Tracking"
      modal-class="modal-primary"
      hide-footer
      scrollable
      @hidden="close"
    >
      <filter-slot
        :no-visible-principal-filter="true"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        :filter="[]"
        :filter-principal="{}"
        @reload="$refs['refTrackingTable'].refresh()"
      >
        <template #table>
          <b-table
            ref="refTrackingTable"
            no-border-collapse
            class="position-relative"
            :fields="fields"
            show-empty
            no-provider-filtering
            sticky-header="50vh"
            primary-key="id"
            responsive="sm"
            :items="searchYearlyBudget"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
            :filter="searchInput"
            :busy.sync="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2 ">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
  
            <template #head(name_event)="data">
              <div class="text-left pt-header">
                {{ data.label }}
              </div>
            </template>

            <template #head(cost)="data">
              <div class="text-center pt-header">
                {{ data.label }}
              </div>
            </template>
  
            <template #head(action_type)="data">
              <div class="text-center pt-header">
                {{ data.label }}
              </div>
            </template>
            <template #head(created_by)="data">
              <div class="text-center">
                {{ data.label }}
              </div>
            </template>
           
            <template #cell(name_event)="data">
              <td class="align-middle text-left  td-padding">
                {{ data.item.name_event }}
              </td>
            </template>


            <template #cell(cost)="data">
              <td class="align-middle text-center d-flex justify-content-center td-padding ">
                <span  class="style-money">S/.</span> {{ data.item.cost | currency }}
              </td>
            </template>

            <template #cell(action_type)="data">
              <td class="align-middle text-center d-flex justify-content-center td-padding">
                    <b-badge
                class="px-1"
                pill
                :variant="statusColor(data.item.action_type)"
              >
              {{  capitalizeAction( data.item.action_type)  }}
              </b-badge>

               
              </td>
            </template>
         
         
  
            <template
              #cell(created_by)="data"
            >
              <td class="align-middle text-center d-flex justify-content-center  align-items-center m-0 p-0">
                {{ data.item.created_by }} <br> {{ data.item.created_at | myGlobalWithHour }}
              </td>
            </template>
  
          </b-table>
        </template>
      </filter-slot>
    </b-modal>
  </template>
  
  <script>
  
  import TrackingYearlyBudgetService from '@/views/logistic/views/budget/services/budget.service.js';
  
  export default {
    props: {
      sendIdDetails: {
        type: Number,
        default: null,
      },
    },
  
    data() {
      return {
        onControl: false,
        startPage: 0,
        toPage: 0,
        isBusy: false,
        sortBy: 'name',
        sortDesc: true,
        searchInput: '',
        fields: [
          {
            key: 'name_event',
            sortable: false,
            label: 'Name',
            class: 'px-1 pt-0 pb-0',
          },
          {
            key: 'cost',
            sortable: false,
            label: 'Cost',
            class: 'px-1 pt-0 pb-0',
          },
          {
            key: 'action_type',
            sortable: false,
            label: 'Action',
            class: 'px-1 pt-0 pb-0',
          },
          {
            key: 'created_by',
            sortable: false,
            label: 'Created by',
          }
        ],
        trackingItems: [],
        totalRows: 1,
        paginate: {
          perPage: 10,
          currentPage: 1,
        },
  
      };
    },
  
    async created() {
      this.onControl = true;
      this.addPreloader();
      this.removePreloader();
    },
    


    methods: {
      close() {
        this.$emit('close');
      },

      statusColor(item){
     
     switch (item) {
       case 'created':
         return "light-success";

       case 'updated' :
       return "light-warning";

       default :
       return "light-danger"
     }

   },
   capitalizeAction(item){
        return item.charAt(0).toUpperCase() + item.slice(1);
      },
  
      async searchYearlyBudget(ctx) {
        const response = await TrackingYearlyBudgetService.getTrackingBudgetEventDetails({
          id: this.sendIdDetails,
          orderby: 'created_at',
          page: ctx.currentPage,
          order: ctx.sortDesc ? 'desc' : 'asc',
          perPage: ctx.perPage,
        });
        
      
        this.trackingItems = response.data;
        this.startPage = response.from;
        this.paginate.currentPage = response.current_page;
        this.paginate.perPage = response.per_page;
        this.totalRows = response.total;
        this.toPage = response.to;
        return this.trackingItems || [];
      },
  
    },
  
  };
  </script>
  <style scoped>
   .dark-layout .table td td{
    border-color: transparent !important;
    padding-right: 0;
    /* align-items: middle; */
  }
  .text-uit{
    font-size: 10px;
    font-weight: bold;
    padding-top: 3px;
    padding-left: 2px;
  
  }
  .style-money{
    padding-right: 3.2px;
  }
  
  .td-padding{
    padding-top:20px !important ;
  }
  .pt-header{
    padding-top: 9.720px !important ;
  }

  .table td>td {
      border-top: none;
      padding-left:  0px;
      padding-right: 0px;
    }
  </style>
  