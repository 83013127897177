<template>
  <div>
    <filter-slot
        :filter="filter"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['refYearlyBudget'].refresh()"
    >
      <template #table>
        <b-table
        ref="refYearlyBudget"
        no-border-collapse
        class="position-relative"
        :fields="fields"
        show-empty
        no-provider-filtering
        sticky-header="50vh"
        primary-key="id"
        responsive="sm"
        :items="getDataYearlyBudget"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
        :filter="searchInput"
        :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
         

          
          <template #cell(budget)="data">
              <td class="align-middle text-center d-flex justify-content-center text-primary">
                <span  class="style-money">S/.</span> {{ data.item.budget | currency }}
              </td>
            </template> 
            
            <template #cell(available)="data">
              <td class="align-middle text-center d-flex justify-content-center" 
              :style="data.item.budget - data.item.used > 0 ? 'color:#9fed4c' : 'color: #f90e0e'"
              >
                <span  class="style-money">S/.</span> {{ data.item.budget - data.item.used | currency }}
              </td>
            </template> 

            <template #cell(used)="data">
              <td class="align-middle text-center d-flex justify-content-center" style="color:#f4c20fee;">
                <span  class="style-money">S/.</span> {{ data.item.used | currency }}
              </td>
            </template> 
            
            <template #cell(detail)="data">
              {{ data.item.detail === '' || data.item.detail === null  ? ' - '  : data.item.detail  }}
            </template>   

          <template #cell(assign)="data">
            <div>
                <b-button
                size="sm"
                variant="outline-success"
                @click="openBudgetEvent(data.item)"
                > 

                <tabler-icon
                icon="ExternalLinkIcon"
                size="16"
              />
                Manage Events</b-button
                >
            </div>
          </template>

          <template #cell(actions)="data">
           <td class="align-middle text-center d-flex justify-content-center">
             <feather-icon
                 v-b-tooltip.hover.top="'Edit'"
                 class="mr-1 pointer text-warning"
                 icon="EditIcon"
                 size="15"
                 @click="openEditModalYearlyBudget(data.item)"
             />
             <feather-icon
                 v-b-tooltip.hover.top="'Delete'"
                 class=" pointer text-danger"
                 icon="TrashIcon"
                 size="15"
                 @click="deleteYearlyBudget(data.item)"
             />
           </td>
          </template>

          <template #cell(tracking)="data">

            <feather-icon 
                v-b-tooltip.hover.top="'Tracking'"
                class="text-primary cursor-pointer"
                icon="ListIcon"
                size="15"
                @click="ModalTrackingYearlyBudget(data.item)"
              />
          </template>

        </b-table>
      </template>
    </filter-slot>
     
    <modal-new-budget 
        v-if="openModalNewBudget"
        :sendItem = "dataItem"
        :sendMod = "mod"
        :sendDataYear ="dataYearBudget"
        @close="closeModalNewBudget"
        @reload="$refs['refYearlyBudget'].refresh()"
    />

    <modal-tracking-budget 
        v-if="openModalTrackingBudget"
        :sendIdBudget = "idBudget"
        @close="closeModalTrackingBudget"
        @reload="$refs['refYearlyBudget'].refresh()"
    />

    <sidebar-budget-event
      v-if="openSidebarBudgetEvent"
      :sendArrBudget="arrBudget"
      @close="openSidebarBudgetEvent = false"
       @reload="$refs['refYearlyBudget'].refresh()"
    
    />

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModalNewBudget from '@/views/logistic/views/budget/views/components/modals/NewBudget.vue';
import fieldData from "@/views/logistic/views/budget/views/data/fields.data.js"
import BudgetService from "@/views/logistic/views/budget/services/budget.service.js";
import ModalTrackingBudget from "@/views/logistic/views/budget/views/components/modals/TrackingYearlyBudget.vue"
import SidebarBudgetEvent from "@/views/logistic/views/budget/views/components/sidebars/SidebarBudgetEvent.vue"

export default {
    components:{
        ModalNewBudget,
        ModalTrackingBudget,
        SidebarBudgetEvent
    },
    data() {
        return {
            filter: [], /* archivo para hacer filtro con los años */
            openModalNewBudget:false,
            openModalTrackingBudget:false,
            openSidebarBudgetEvent:false,
            mod :false,
            dataItem:{},
            idBudget:null,
            arrBudget:[],
            dataYearBudget :[], 
            filterPrincipal: {
            type: 'input',
            inputType: 'text',
            placeholder: 'Search...',
            model: '',
            },
            fields :fieldData,
            totalRows: 1,
            paginate: {
                perPage: 10,
                currentPage: 1,
            },
            selectedItem: 0,
            startPage: 0,
            toPage: 0,
            isBusy: false,
            sortBy: 'name',
            sortDesc: true,
            searchInput: ''
        }
    },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    openBudgetEvent(item){

      this.arrBudget = [];
      this.arrBudget.push(
        {
          id : parseInt(item.id),  
          year:parseInt(item.year),
          budget:parseFloat(item.budget),
          available:parseFloat(item.budget) - parseFloat(item.used)
        } 
      )
      this.openSidebarBudgetEvent = true;

    },
    openNewBudget() {
      this.openModalNewBudget=true;
    },
    closeModalNewBudget(){
      this.openModalNewBudget=false;
      this.mod =false;
      this.dataItem = {};
    },

    closeModalTrackingBudget(){
      this.openModalTrackingBudget = false;
    },

    openEditModalYearlyBudget(item){
        this.openModalNewBudget=true;
        this.dataItem = item;

        
        this.mod = true;

    },
    ModalTrackingYearlyBudget(item){
     
      this.openModalTrackingBudget = true;
      this.idBudget = item.id;

    },

    async deleteYearlyBudget(item){
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
            const data = await BudgetService.deleteYearlyBudget({
                id : item.id,
                budget : item.budget,
                created_by : this.currentUser.user_id,
             });

            

            if (data.status === 200) {
            this.showSuccessSwal("Success", "Budget has been deleted successfully");
            // this.showGenericSuccessSwal({});
            }
            this.$refs.refYearlyBudget.refresh()
      
        }
   
      
      } catch (error) {
        this.showErrorSwal(error);
      }

    },

    async getDataYearlyBudget(ctx){

        try {
           
                const params = {
                    perPage: ctx.perPage,
                    name_text: this.filterPrincipal.model,
                    orderby: 'year',
                    page: ctx.currentPage,
                    order: ctx.sortDesc == 1 ? 'desc' : 'asc',
                    text: this.filterPrincipal.model,
                    from: '',
                    to: ''

                }
                const data = await BudgetService.getYearlyBudget(params);
               
                
                this.items = data.data
                this.startPage = data.from
                this.paginate.currentPage = data.current_page
                this.paginate.perPage = data.per_page
                this.nextPage = this.startPage + 1
                this.endPage = data.last_page
                this.totalRows = data.total;
                
                this.dataYearBudget = [];
                data.data.forEach(element => {
                    this.dataYearBudget.push(parseInt(element.year) );
                });
               
                this.toPage = data.to
                return this.items || []

            } catch (error) {
                this.showErrorSwal(error);
                return [];
            }
    }
  },
};
</script>

<style scoped>
   .style-money{
    padding-right: 3.2px;
  }
  .table td>td {
      border-top: none;
      padding: 0px;
    }
</style>