<template>
	<div>
		<header-slot>
			<template #actions>
				<b-button @click="openNewBudget()" variant="outline-success">
					<feather-icon icon="PlusIcon" class="text-success" />
					<span> New Budget</span>
				</b-button>
			</template>
		</header-slot>
		<b-card class="border-top-primary border-3 border-table-radius px-0">
			<yearly-budget ref="yearlyBudget" />
		</b-card>
	</div>
</template>
<script>
import YearlyBudget from "@/views/logistic/views/budget/views/YearlyBudget.vue"
export default {
  components: {
    YearlyBudget
  },
  methods: {
	openNewBudget() {
		this.$refs.yearlyBudget.openNewBudget()
	}
  }
};
</script>