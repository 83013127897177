<template >
    <div class="mt-3">
        <div class="d-flex justify-content-end mr-2">
            <b-button @click="openNewBudget()" variant="outline-success">
                <feather-icon icon="PlusIcon" />
                <span> New Event</span>
                 
            </b-button>
        </div>

    <filter-slot
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :filter="filter"
      :backdrop-close="true"
      :filter-principal="filterPrincipal"
      @reload="$refs['refEventTable'].refresh()"
    >
      <template #table>
        <b-table
          ref="refEventTable"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="searchBudgetEvent"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :filter="searchInput"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #head(name)="data">
            <div class="text-left pt-header">
              {{ data.label }}
            </div>
          </template>

          <template #head(month)="data">
            <div class="text-center pt-header">
              {{ data.label }}
            </div>
          </template>

          <template #head(day)="data">
            <div class="text-center pt-header">
              {{ data.label }}
            </div>
          </template>

          <template #head(holiday)="data">
            <div class="text-center pt-header">
              {{ data.label }}
            </div>
          </template>

          <template #head(country)="data">
            <div class="text-center pt-header">
              {{ data.label }}
            </div>
          </template>
          <template #head(type)="data">
            <div class="text-center pt-header">
              {{ data.label }}
            </div>
          </template>

          <template #head(action)="data">
            <div class="text-center pt-header">
              {{ data.label }}
            </div>
          </template>

          <template #head(cost)="data">
            <div class="text-center pt-header">
              {{ data.label }}
            </div>
          </template>

          <template #head(description)="data">
            <div class="text-center pt-header">
              {{ data.label }}
            </div>
          </template>

          <template #head(tracking)="data">
            <div class="text-center pt-header">
              {{ data.label }}
            </div>
          </template>

          <template #cell(name)="data">
            <td class="align-middle text-left td-padding">
              {{ data.item.name }}
            </td>
          </template>

          <template #cell(month)="data">
            <td class="align-middle d-flex justify-content-center td-padding">
              {{ data.item.month }}
            </td>
          </template>

          <template #cell(day)="data">
            <td class="align-middle d-flex justify-content-center td-padding">
              {{ data.item.day }}
            </td>
          </template>

          <template #cell(holiday)="data">
            <td class="align-middle d-flex justify-content-center td-padding">
              <b-badge
                class="px-1"
                pill
                :variant="statusColor(data.item.holiday)"
              >
                {{ data.item.holiday === 0 ? "No" : "Yes" }}
              </b-badge>
            </td>
          </template>

          <template #cell(country)="data">
            <td class="align-middle d-flex justify-content-center td-padding">
              <!-- {{ data.item.country }} -->
              <img
                :src="data.item.country === 'PE' ? imgPeru : imgUsa"
                height="20px"
                alt="logo"
              />
            </td>
          </template>

          <template #cell(type)="data">
            <td class="align-middle d-flex justify-content-center td-padding">
              {{ data.item.type }}
            </td>
          </template>

          <template #cell(cost)="data">
            <td
              class="align-middle text-center d-flex justify-content-center td-padding"
            >
              <span class="style-money">S/.</span> {{ data.item.cost | currency }}
            </td>
          </template>

          <template #cell(description)="data">
            <td class="align-middle text-center d-flex justify-content-center td-padding">
              {{ data.item.description === '' || data.item.description === null  ? ' - '  : data.item.description  }}
            </td>
          </template>

          <template #cell(action)="data">
            <td class="align-middle d-flex justify-content-center td-padding">
              <feather-icon
                v-b-tooltip.hover.top="'Edit'"
                class="mr-1 pointer text-warning"
                icon="EditIcon"
                size="15"
                @click="openEditModalBudgetEvent(data.item)"
              />
              <feather-icon
                v-b-tooltip.hover.top="'Delete'"
                class=" pointer text-danger"
                icon="TrashIcon"
                size="15"
                @click="deleteBudgetEventDetails(data.item)"
              />
            </td>
          </template>

          <template #cell(tracking)="data">
            <td class="align-middle d-flex justify-content-center td-padding">
              <feather-icon
                v-b-tooltip.hover.top="'Tracking'"
                class="text-primary pointer"
                icon="ListIcon"
                size="15"
                @click="openListTrackingBudgetEventDetails(data.item)"
              />
            </td>
          </template>

          <template #custom-foot>
            <b-tr>
              <b-td colspan="5"></b-td>
              <b-td class="align-middle d-flex justify-content-center">
                <div
                  class="text-border align-middle d-flex justify-content-center"
                >
                  <span class="text-total text-light">Sub Total</span>
                </div>
              </b-td>

              <b-td class="text-center">
                <span class="text-value">
                  S/. {{ subTotalCost | currency }}
                </span>
              </b-td>
              <b-td colspan = 2></b-td>
            </b-tr>
            <b-tr style="border:none;">
                <b-td colspan = 5 style="border:none !important;"></b-td>
                <b-td class="align-middle d-flex justify-content-center" style="border:none !important;">
                    <div class ="text-border">
                        <span class="text-total text-light">Total</span>
                    </div>
                </b-td>
          
              <b-td class="text-center" style="border:none !important;">  
                  <span class="text-value"> 
                  S/. {{ totalCost | currency }}
                  </span>
                </b-td>
            </b-tr>

            </template>
  
        </b-table>
         
        </template>
      </filter-slot>



        <modal-tracking-budget-event-details
        v-if="openModalTrackingBudgetEventDetails"
        :sendIdDetails = "dataIdDetalle"
        @close="closeModalTrackingBudgetDetails"
    />

    <modal-new-budget-event
      :sendIdBudget="idBudget"
      :sendIdBugetDetails="idBudgetDetails"
      :sendMod="mod"
      :sendDataEvent="dataNameEvent"
      :sendBudgetTotal="valueBudget"
      :sendCostTotal="valueTotal"
      v-if="openModalNewBudgetEvent"
      @close="closeModalNewBudgetEvent"
      @reloadTable="$refs['refEventTable'].refresh()"
      @reloadBudgetTable = "refreshBudgetTable"
    />
  </div>
</template>
<script>
import ModalNewBudgetEvent from "@/views/logistic/views/budget/views/components/modals/NewBudgetEvent.vue";
import BudgetService from "@/views/logistic/views/budget/services/budget.service.js";
import ModalTrackingBudgetEventDetails from "@/views/logistic/views/budget/views/components/modals/TrackingBudgetEventDetails.vue";
import EventFilter from "@/views/logistic/views/budget/views/data/filters.js";
import peruSVG from "@/assets/images/icons/flag-peru.svg";
import usaSVG from "@/assets/images/icons/flag-eeuu.svg";

import { mapGetters } from "vuex";

export default {
  props: {
    sendBudgetId: {
      type: Number,
      default: null,
    },
    sendYearBudget: {
      type: Number,
      default: null,
    },
    sendValueBugdet: {
      type: Number,
      default: null,
    },
  },

  components: {
    ModalNewBudgetEvent,
    ModalTrackingBudgetEventDetails,
  },
  data() {
    return {
      isDetailsActive: false,
      valueBudget: 0,
      openModalNewBudgetEvent: false,
      idBudget: 0,
      dataIdDetalle: 0,
      totalCost: 0,
      subTotalCost: 0,
      valueTotal: 0,
      imgPeru: peruSVG,
      imgUsa: usaSVG,
      dataBudgetEvent: [],
      totalBudget: 0,
      dataNameEvent: [],
      dataNameEvent2: [],
      openModalTrackingBudgetEventDetails: false,
      idBudgetDetails: null,
      mod: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Name of Event...",
        model: "",
      },
      filter: EventFilter,
      onControl: false,
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: "name",
      sortDesc: true,
      searchInput: "",
      fields: [
        {
          key: "name",
          sortable: false,
          label: "EVENT",
          class: "px-1 pt-0 pb-0",
        },
        {
          key: "month",
          sortable: false,
          label: "MONTH",
          class: "px-1 pt-0 pb-0",
        },
        {
          key: "day",
          sortable: false,
          label: "DAY",
          class: "px-1 pt-0 pb-0",
        },
        {
          key: "holiday",
          sortable: false,
          label: "HOLIDAY",
          class: "px-1 pt-0 pb-0",
        },
        {
          key: "country",
          sortable: false,
          label: "COUNTRY",
          class: "px-1 pt-0 pb-0 text-center",
        },
        {
          key: "type",
          sortable: false,
          label: "TYPE",
          class: "px-1 pt-0 pb-0",
        },
        {
          key: "cost",
          sortable: false,
          label: "COST",
          class: "px-1 pt-0 pb-0",
        },
        {
          key: "description",
          sortable: false,
          label: "DETAIL",
          class: "px-1 pt-0 pb-0",
        },
        {
          key: "action",
          sortable: false,
          label: "Action",
          class: "px-1 pt-0 pb-0",
        },
        {
          key: "tracking",
          sortable: false,
          label: "Tracking",
          class: "px-1 pt-0 pb-0",
        },
      ],
      trackingItems: [],
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
    };
  },

  async created() {
    this.valueBudget = this.sendValueBugdet;
    this.getSelectMonth();
    await Promise.all([this.getSelectEventType(), this.getSelectCountry()]);
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {},
  methods: {
    async getSelectEventType() {
      const { data } = await BudgetService.getEventType();
      this.filter[1].options = data;
    },

    async getSelectCountry() {
      const { data } = await BudgetService.getCountry();
      this.filter[2].options = data;
    },

    getSelectMonth() {
      const dataMonth = [
        { id: 1, name: "January" },
        { id: 2, name: "February" },
        { id: 3, name: "March" },
        { id: 4, name: "April" },
        { id: 5, name: "May" },
        { id: 6, name: "June" },
        { id: 7, name: "July" },
        { id: 8, name: "August" },
        { id: 9, name: "September" },
        { id: 10, name: "Octuber" },
        { id: 11, name: "November" },
        { id: 12, name: "December" },
      ];
      this.filter[0].options = dataMonth;
    },

    statusColor(item) {
      switch (item) {
        case 0:
          return "light-danger";

        default:
          return "light-success";
      }
    },

    async searchBudgetEvent(ctx) {
      const { data } = await BudgetService.getBudgetEvent({
        year_id: this.sendBudgetId,
        month: this.filter[0].model,
        type: this.filter[1].model,
        country: this.filter[2].model,
        holiday: parseInt(this.filter[3].model),
        text: this.filterPrincipal.model,
        page: ctx.currentPage,
        perPage: ctx.perPage,
      });


      if (data.data.length > 0) {
        this.totalCost = data.data[0].total;
        this.subTotalCost = data.data[0].sub_total.toFixed(2);
      } else {
        this.subTotalCost = (0.0).toFixed(2);
        this.totalCost = (0.0).toFixed(2);
      }

      this.valueTotal = parseFloat(this.totalCost);
      this.$emit('updateTotal',this.valueTotal);
      this.trackingItems = data.data;
      this.startPage = data.from;
      this.paginate.currentPage = data.current_page;
      this.paginate.perPage = data.per_page;
      this.totalRows = data.total;
      this.toPage = data.to;
      return this.trackingItems || [];
    },

    closeModalNewBudgetEvent() {
      this.openModalNewBudgetEvent = false;
      this.mod = false;
    },

    closeModalTrackingBudgetDetails() {
      this.openModalTrackingBudgetEventDetails = false;
    },
    openListTrackingBudgetEventDetails(item) {
      this.openModalTrackingBudgetEventDetails = true;
      this.dataIdDetalle = item.id;
    },

    openNewBudget() {
      this.idBudget = this.sendBudgetId;
      this.openModalNewBudgetEvent = true;
    },

    refreshBudgetTable(){
      this.$emit('reload');
    },

    openEditModalBudgetEvent(item) {
      this.idBudgetDetails = item.id;
      this.mod = true;
      this.openModalNewBudgetEvent = true;
    },

    async deleteBudgetEventDetails(item) {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          const data = await BudgetService.deleteBudgetEventDetails({
            id: item.id,
            cost: item.cost,
            created_by: this.currentUser.user_id,
          });

          if (data.status === 200) {
            this.showSuccessSwal(
              "Success",
              "Event Budget has been deleted successfully"
            );
            // this.showGenericSuccessSwal({});
          }
          this.$refs.refEventTable.refresh();
          this.$emit('reload');
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>
<style scoped >
.pt-header {
  padding: 9.72px 0px !important ;
}
.td-padding {
  padding: 9.72px 0px !important ;
}

.text-total {
  font-size: 1rem;
  font-family:"sans-serif";
  font-weight: bold;
}

.text-border {
  background-color: #0072b8;
  border-radius: 10px;
  width: 100px;
  text-align: center;
  padding: 5px 0px;
}

.text-value {
  display: block;
  margin-top: 7px;
  color: #19589e;
  font-weight: bold;
}
</style>