<template>
  <b-modal
    v-model="onControl"
    :title="formTitle"
    title-tag="h3"
    modal-class="modal-primary"
    @hidden="close"
    size="md"
    no-close-on-backdrop
  >
    <validation-observer ref="budgetForm">
      <div class="d-block">
        <b-container>
          <b-row>
            <b-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                name="rule_title"
                rules="required"
              >
                <b-form-group label="Title:" label-for="txtTitle">
                  <b-form-input
                    id="txtTitle"
                    v-model="title"
                    type="text"
                    placeholder="Ttile"
                    :state="errors[0] ? false : null"
                  ></b-form-input>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="6" sm="6">
              <validation-provider
                v-slot="{ errors }"
                name="rule_budget"
                rules="validate-amount"
              >
                <b-form-group label="Budget:" label-for="txtbudget">
                  <money
                    v-model="budget"
                    v-bind="vMoney"
                    class="form-control"
                    :class="errors[0] ? 'border-danger' : ''"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="6" sm="6">
              <validation-provider
                v-slot="{ errors }"
                name="rule_year"
                rules="required"
              >
                <b-form-group
                  id="input-group-2"
                  label="Year:"
                  label-for="txtYear"
                >
                  <b-form-select
                    v-model="selectedYear"
                    :disabled="disabledYear"
                    :state="errors[0] ? false : null"
                  >
                    <option v-for="year in dataYears" :key="year" :value="year">
                      {{ year }}
                    </option>
                  </b-form-select>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <validation-provider    
              v-slot="{ errors }" 
              name="rule_detail" 
              rules="valid-detail"
              >
                <b-form-group
                  id="input-group-3"
                  label="Detail:"
                  label-for="txtDetail"
                >
                  <b-form-textarea
                    id="txtDetail"
                    size="sm"
                    v-model="detail"
                    placeholder="Detail"
                    :state="errors[0] ? false : null"
                    style="font-size:13.5px"
                  ></b-form-textarea>
                  <span
                    v-if="errors[0]"
                    class="text-danger"
                    >
                    {{ errors[0] }}</span>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </validation-observer>

    <template #modal-footer="{ cancel }">
      <button-cancel @click="cancel()"></button-cancel>
      <button-save @click="saveBudget">Guardar</button-save>
    </template>
  </b-modal>
</template>

<script>
import buttonSave from "@/views/commons/utilities/ButtonSave";
import buttonCancel from "@/views/commons/utilities/ButtonCancel";
import BudgetService from "@/views/logistic/views/budget/services/budget.service.js";
import { mapGetters } from "vuex";

export default {
  components: {
    buttonSave,
    buttonCancel,
  },

  props: {
    sendItem: {
      type: Object,
      default: function () {
        return {};
      },
    },
    sendMod: {
      type: Boolean,
      default: false,
    },
    sendDataYear: {
      type: Array,
      default: [],
    },
  },

  data() {
    return {
      vMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "S/. ",
        precision: 2,
        masked: false,
        maxlength: 13,
      },
      idBudget: "",
      title: "",
      formTitle: "Create Budget",
      budget: "",
      disabledYear: false,
      year: "",
      detail: "",
      years: [],
      dataYears: [],
      selectedYear: null,
      onControl: false,
    };
  },
  created() {
    this.onControl = true;
  },
  mounted() {
    if (this.sendMod) {
      const currentYear = new Date().getFullYear();
      this.dataYears = Array.from({ length: 5 }, (value, index) =>
        parseInt(currentYear + index)
      );
      this.idBudget = this.sendItem.id;
      this.title = this.sendItem.title;
      this.budget = this.sendItem.budget;
      this.selectedYear = this.sendItem.year;
      this.detail = this.sendItem.detail;
      this.disabledYear = true;
      this.formTitle = "Edit Budget";
    } else {
      this.getListYear();
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    close() {
      this.$emit("close");
    },

    getListYear() {
      const currentYear = new Date().getFullYear();

      this.years = Array.from({ length: 5 }, (value, index) =>
        parseInt(currentYear + index)
      );

      this.dataYears = this.years.filter(
        (elemento) => !this.sendDataYear.includes(elemento)
      );
    },

    async saveBudget() {
      try {
        const resultValidation = await this.$refs.budgetForm.validate();

        if (resultValidation) {
          const response = await this.showConfirmSwal();

          if (response.isConfirmed) {
            this.addPreloader();
            const data = await BudgetService.saveYearlyBudget({
              id: this.idBudget,
              title: this.title,
              budget: this.budget,
              detail: this.detail,
              year: this.selectedYear,
              created_by: this.currentUser.user_id,
            });

            if (data.status === 200) {
              // await this.getSettingFifthCategory();
              this.removePreloader();
              this.showGenericSuccessSwal({});
              this.close();
              this.$emit("reload");
            }
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>

<style scoped>
</style>